import { ROUTE_TYPE } from '@/constants';
import hasMakeFreightRequestsPermission from '@/domains/permissions/subscription/hasMakeFreightRequestsPermission';
import { canBrowseDirectionsByRole } from '@/domains/permissions/role/directions';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { getIsTenantWithCustomization } from '@/domains/getIsTenantWithCustomization';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { hasCargoOwnerWorkplaceSubscription } from '@/domains/permissions/subscription';
import { EExperimentalFeatures } from '@/domains/constants';

import { ROUTE_NAME } from '../domain/constants';

const hasMakeFreightRequestsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    : hasMakeFreightRequestsPermission());

export default [
  buildProtectedRoute({
    path: '/directions',
    name: ROUTE_NAME.directions,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => !getIsTenantWithCustomization(),
        hasMakeFreightRequestsSubscription,
        canBrowseDirectionsByRole,
      ],
      component: () => import('@/contexts/directionsContext/views/DirectionsListView/index.vue'),
    },
  }),
];
