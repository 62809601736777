import tt from '@/i18n/utils/translateText';
import { capitalize } from '@/utils';
import { EStatusType } from '@/domains/constants';

export enum EFreightRequestStatus {
  canceled = 'canceled',
  active = 'active',
  completed = 'completed',
}
export const FREIGHT_REQUEST_STATUS_MODEL = {
  [EFreightRequestStatus.active]: {
    text: tt('order.shared.statuses.active'),
    type: EStatusType.processing,
  },
  [EFreightRequestStatus.completed]: {
    text: tt('order.shared.statuses.completed'),
    type: EStatusType.success,
  },
  [EFreightRequestStatus.canceled]: {
    text: tt('order.shared.statuses.canceled'),
    type: EStatusType.error,
  },
};

export enum EOrderStatus {
  canceled = 'canceled',
  active = 'active',
  completed = 'completed',
}
export const ORDER_STATUS_MODEL = {
  [EOrderStatus.active]: {
    text: tt('order.shared.statuses.active'),
    type: EStatusType.processing,
  },
  [EOrderStatus.completed]: {
    text: tt('order.shared.statuses.completed'),
    type: EStatusType.success,
  },
  [EOrderStatus.canceled]: {
    text: tt('order.shared.statuses.canceled'),
    type: EStatusType.error,
  },
};

export enum EOrderState {
  active = 'active',
  executionsRequestsCanceled = 'executions_requests_canceled',
  canceled = 'canceled',
  completed = 'completed',
}

export const ROUTE_NAME = {
  ordersList: 'ordersList',
  ordersListOnlyOrders: 'ordersListOnlyOrders',
  shipmentsInfoView: 'shipmentsInfoView',
  shipmentsListView: 'shipmentsListView',
};

export enum ELookupMethod {
  spotOffer = 'SpotOffersContext::SpotOffer',
  targetOffer = 'TargetOffersContext::TargetOffer',
  reduction = 'ReductionsContext::Reduction',
  quota = 'QuotasContext::Distribution',
  freeOffering = 'FreeOfferingsContext::FreeOffering',
}
export enum EExternalLookupMethod {
  reduction = 'ReductionsContext::External::Reduction',
  spotOffer = 'SpotOffersContext::External::SpotOffer',
  targetOffer = 'TargetOffersContext::External::TargetOffer',
  quota = 'QuotasContext::External::Distribution',
}

export enum ELookupMethodType {
  spotOffer = 'spotOffer',
  targetOffer = 'targetOffer',
  reduction = 'reduction',
  quota = 'quota',
  freeOffering = 'freeOffering',
}

export enum ELookupMethodTypeInSnakeCase {
  spotOffer = 'spot_offer',
  targetOffer = 'target_offer',
  reduction = 'reduction',
  quota = 'quota',
  freeOffering = 'free_offering',
}

export const LOOKUP_METHOD_TYPE_ICON = {
  [ELookupMethod.targetOffer]: 'TargetOfferSvg',
  [ELookupMethod.reduction]: 'TenderSvg',
  [ELookupMethod.spotOffer]: 'SpotOfferSvg',
  [ELookupMethod.quota]: 'GuaranteeSvg',
  [ELookupMethod.freeOffering]: 'FreeOfferingSvg',
};
export const EXTERNAL_LOOKUP_METHOD_TYPE_ICON = {
  [EExternalLookupMethod.reduction]: 'TenderSvg',
  [EExternalLookupMethod.spotOffer]: 'SpotOfferSvg',
  [EExternalLookupMethod.targetOffer]: 'TargetOfferSvg',
  [EExternalLookupMethod.quota]: 'GuaranteeSvg',
};

export const LOOKUP_METHOD_TYPE_KEYS = {
  [ELookupMethod.targetOffer]: 'targetOffer',
  [ELookupMethod.reduction]: 'reduction',
  [ELookupMethod.spotOffer]: 'spotOffer',
  [ELookupMethod.quota]: 'quota',
  [ELookupMethod.freeOffering]: 'freeOffering',
};
export const EXTERNAL_LOOKUP_METHOD_TYPE_KEYS = {
  [EExternalLookupMethod.reduction]: 'reduction',
  [EExternalLookupMethod.spotOffer]: 'spotOffer',
  [EExternalLookupMethod.targetOffer]: 'targetOffer',
  [EExternalLookupMethod.quota]: 'quota',
};

export const LOOKUP_METHOD_TRANSLATION = {
  [ELookupMethod.targetOffer]: tt('shared.targetOffer'),
  [ELookupMethod.quota]: tt('order.shared.lookupMethods.quota'),
  [ELookupMethod.freeOffering]: tt('executorsLookup.shared.freeOffering'),
  [ELookupMethod.reduction]: tt('shared.reduction'),
  [ELookupMethod.spotOffer]: tt('shared.spotOffer'),
};
export const EXTERNAL_LOOKUP_METHOD_TRANSLATION = {
  [EExternalLookupMethod.reduction]: tt('shared.reduction'),
  [EExternalLookupMethod.spotOffer]: tt('shared.spotOffer'),
  [EExternalLookupMethod.targetOffer]: tt('shared.targetOffer'),
  [EExternalLookupMethod.quota]: tt('executorsLookup.shared.quota'),
};

export const LOOKUP_ROUTE_BY_TYPE = {
  [ELookupMethod.spotOffer]: 'spot_offers',
  [ELookupMethod.targetOffer]: 'target_offers',
  [ELookupMethod.reduction]: 'reductions',
  [ELookupMethod.quota]: 'target_offers',
  [ELookupMethod.freeOffering]: 'free_offerings',
  [EExternalLookupMethod.reduction]: 'external/reductions',
  [EExternalLookupMethod.spotOffer]: 'external/spot_offers',
};

export const LOOKUP_DRAWER_ROUTE_BY_TYPE = {
  [ELookupMethod.spotOffer]: 'spot_offers',
  [ELookupMethod.targetOffer]: 'target_offers',
  [ELookupMethod.reduction]: 'reductions',
  [ELookupMethod.quota]: 'target_offers',
  [ELookupMethod.freeOffering]: 'free_offerings',
  [EExternalLookupMethod.reduction]: 'external/reductions',
  [EExternalLookupMethod.spotOffer]: 'external/spot_offers',
  [EExternalLookupMethod.targetOffer]: 'external/target_offers',
};

export enum EAutomationLaunchStatus {
  active = 'active',
  finished = 'finished',
  aborted = 'aborted',
}

export enum ELaunchType {
  manual = 'manual',
  automatic = 'automatic',
}

export const LAUNCH_TYPE_TRANSLATION = {
  [ELaunchType.manual]: tt('order.shared.launchTypes.manual'),
  [ELaunchType.automatic]: tt('order.shared.launchTypes.automatic'),
};

export enum EStageType {
  started = 'started',
  next = 'next',
}

export enum EStartedStageStatus {
  started = 'started',
  failed = 'failed',
  planned = 'planned',
}

export const STAGE_STATUS_TRANSLATION = {
  [EStartedStageStatus.started]: tt('order.shared.stageStatuses.started'),
  [EStartedStageStatus.failed]: tt('order.shared.stageStatuses.failed'),
  [EStartedStageStatus.planned]: tt('order.shared.stageStatuses.planned'),
};

export enum ELookupObject {
  automationLaunch = 'automation_launch',
  simpleExecutorsLookup = 'simple_executors_lookup',
}

export enum EOrderType {
  general = 'general',
  container = 'container',
  bulk = 'bulk',
}

export enum EAccountingUnit {
  run = 'run',
  ton = 'ton',
  cubicMeter = 'cubic_meter',
}

export const UNIT_NAME_TRANSLATION = {
  [EAccountingUnit.run]: capitalize(tt('shared.runs.declensions', { param: { n: 1 } })),
  [EAccountingUnit.ton]: capitalize(tt('shared.units.tons.declensions', { param: { n: 1 } })),
  [EAccountingUnit.cubicMeter]: capitalize(tt('shared.units.cubicMeters.declensions', { param: { n: 1 } })),
};

export const UNIT_PLURAL_NAME_TRANSLATION = {
  [EAccountingUnit.run]: capitalize(tt('shared.runs.plural')),
  [EAccountingUnit.ton]: capitalize(tt('shared.units.tons.plural')),
  [EAccountingUnit.cubicMeter]: tt('shared.units.cubicMeters.plural'),
};

export const UNIT_NAME_DECLENSION_TRANSLATION = {
  [EAccountingUnit.run]: tt('shared.runs.declensions', { param: { n: 5 } }),
  [EAccountingUnit.ton]: tt('shared.units.tons.declensions', { param: { n: 5 } }),
  [EAccountingUnit.cubicMeter]: tt('shared.units.cubicMeters.declensions', { param: { n: 5 } }),
};

export const PRICE_FOR_UNIT_TRANSLATION = {
  [EAccountingUnit.ton]: tt('shared.units.tons.accusative'),
  [EAccountingUnit.run]: tt('shared.runs.declensions', { param: { n: 1 } }),
  [EAccountingUnit.cubicMeter]: tt('shared.units.cubicMeters.shortest'),
};

export const UNIT_SHORT_TRANSLATION = {
  [EAccountingUnit.ton]: tt('shared.units.tons.short'),
  [EAccountingUnit.run]: tt('shared.runs.declensions', { param: { n: 1 } }),
  [EAccountingUnit.cubicMeter]: tt('shared.units.cubicMeters.shortest'),
};

export enum EPackagingType {
  drums = 'drums',
  bigBags = 'big_bags',
  bobbins = 'bobbins',
  barrels = 'barrels',
  eurocubes = 'eurocubes',
  canisters = 'canisters',
  reels = 'reels',
  boxes = 'boxes',
  sheets = 'sheets',
  sacks = 'sacks',
  heaping = 'heaping',
  pallets = 'pallets',
  eurPallets = 'eur_pallets',
  finPallets = 'fin_pallets',
  usaPallets = 'usa_pallets',
  packs = 'packs',
  pyramid = 'pyramid',
  scattering = 'scattering',
  rolls = 'rolls',
  crates = 'crates',
}

export const PACKAGING_TYPE_TRANSLATION = {
  [EPackagingType.drums]: tt('order.shared.packagingTypes.drums'),
  [EPackagingType.bigBags]: tt('order.shared.packagingTypes.bigBags'),
  [EPackagingType.bobbins]: tt('order.shared.packagingTypes.bobbins'),
  [EPackagingType.barrels]: tt('order.shared.packagingTypes.barrels'),
  [EPackagingType.eurocubes]: tt('order.shared.packagingTypes.eurocubes'),
  [EPackagingType.canisters]: tt('order.shared.packagingTypes.canisters'),
  [EPackagingType.reels]: tt('order.shared.packagingTypes.reels'),
  [EPackagingType.boxes]: tt('order.shared.packagingTypes.boxes'),
  [EPackagingType.sheets]: tt('order.shared.packagingTypes.sheets'),
  [EPackagingType.sacks]: tt('order.shared.packagingTypes.sacks'),
  [EPackagingType.heaping]: tt('order.shared.packagingTypes.heaping'),
  [EPackagingType.pallets]: tt('order.shared.packagingTypes.pallets'),
  [EPackagingType.eurPallets]: tt('order.shared.packagingTypes.eurPallets'),
  [EPackagingType.finPallets]: tt('order.shared.packagingTypes.finPallets'),
  [EPackagingType.usaPallets]: tt('order.shared.packagingTypes.usaPallets'),
  [EPackagingType.packs]: tt('order.shared.packagingTypes.packs'),
  [EPackagingType.pyramid]: tt('order.shared.packagingTypes.pyramid'),
  [EPackagingType.scattering]: tt('order.shared.packagingTypes.scattering'),
  [EPackagingType.rolls]: tt('order.shared.packagingTypes.rolls'),
  [EPackagingType.crates]: tt('order.shared.packagingTypes.crates'),
};

export const PACKAGING_TYPE_WITHOUT_QUANTITY = [EPackagingType.scattering, EPackagingType.heaping];

export enum EShipmentType {
  selfDelivery = 'self_delivery',
}

export enum EShipmentStatus {
  waitingBooking = 'waiting_booking',
  waitingOperations = 'waiting_operations',
  done = 'done',
  cancelled = 'cancelled',
}

export enum EIncidentType {
  lookupMethodFailed = 'lookup_method_failed',
  automationLaunchFailed = 'automation_launch_failed',
  canceledByExecutor = 'canceled_by_executor',
}

export const INCIDENT_TYPE_KEYS = {
  [EIncidentType.lookupMethodFailed]: 'lookupMethodFailed',
  [EIncidentType.automationLaunchFailed]: 'automationLaunchFailed',
  [EIncidentType.canceledByExecutor]: 'canceledByExecutor',
};

export enum EOrdersQuickActionType {
  cancelOrders = 'cancel_orders',
  changeExecutorsLookupExpiration = 'change_executors_lookup_expiration',
  makeTargetOffer = 'make_target_offer',
  makeReduction = 'make_reduction',
  makeSpotOffer = 'make_spot_offer',
  makeFreeOffering = 'organize_free_offer',
  applyAutomationLaunch = 'apply_automation_launch',
  acceptWinners = 'accept_winners',
  resetOrders = 'reset_orders',
  resumeExecutorsLookups = 'resume_executors_lookups',
  confirmProposedRate = 'confirm_proposed_rate',
  makeCombinedOrder = 'make_combined_order',
}

export enum EWeightUnit {
  kg = 'kg',
  ton = 'ton',
}

export const WEIGHT_UNIT_TRANSLATION = {
  [EWeightUnit.kg]: tt('shared.kg'),
  [EWeightUnit.ton]: tt('shared.ton'),
};

export const WEIGHT_UNIT_OPTIONS = [
  {
    value: EWeightUnit.kg,
    label: tt('shared.kg'),
  },
  {
    value: EWeightUnit.ton,
    label: tt('shared.ton'),
  },
];

export enum ERegisteredQuantityRateAction {
  cargoLoading = 'cargo_loading',
  cargoUnloading = 'cargo_unloading',
}

export const REGISTERED_QUANTITY_RATE_ACTION_TRANSLATION = {
  [ERegisteredQuantityRateAction.cargoLoading]: tt('shared.loading'),
  [ERegisteredQuantityRateAction.cargoUnloading]: tt('shared.unloading'),
};

export const REGISTERED_QUANTITY_RATE_ACTION_OPTIONS = [
  {
    value: ERegisteredQuantityRateAction.cargoLoading,
    label: tt('shared.loading'),
  },
  {
    value: ERegisteredQuantityRateAction.cargoUnloading,
    label: tt('shared.unloading'),
  },
];

export enum EModality {
  auto = 'auto',
  railway = 'railway',
  sea = 'sea',
  air = 'air',
}

export enum EPriceAnalyticsResponseError {
  analyticsIntegrationError = 'DomainErrors::ExecutorsLookupContext::AnalyticsIntegrationError',
  priceAnalyticsNoEnoughData = 'DomainErrors::ExecutorsLookupContext::PriceAnalyticsNoEnoughData',
}

export enum EAddressRequirementsType {
  default = 'default',
  strong = 'strong',
}

export enum EDeliveryType {
  /** Погрузка */
  loadingPointId = 'loadingPointId',
  /** Разгрузка */
  unloadingPointId = 'unloadingPointId',
}

export enum EExtraServicesPriceStatus {
  notSpecified = 'not_specified',
  byAgreement = 'by_agreement',
}

export const EXTRA_SERVICES_PRICE_STATUS_TRANSLATION = {
  [EExtraServicesPriceStatus.notSpecified]: tt('shared.notSpecified.feminine'),
  [EExtraServicesPriceStatus.byAgreement]: tt('extraServices.table.byArrangement'),
};

export enum EQuantityType {
  emptyAssignedQuantity = 'empty_assigned_quantity',
  anyAssignedQuantity = 'any_assigned_quantity',
  emptyAssignedQuantityOrMissingBooking = 'empty_assigned_quantity_or_missing_booking',
}

/** Источник события из контекста заказов (Events::OrderingContext) */
export enum EOrderEventSource {
  /** через форму (к примеру создание/копирование заказа) */
  ui = 'ui',
  /** загрузка из excel файла */
  import = 'import',
  /** через интеграции */
  integration = 'integration',
}

export enum EOrderFolderSlug {
  on_execution = 'on_execution',
  finalized = 'finalized',
  pending = 'pending',
  on_bidding = 'on_bidding',
  on_target_offering = 'on_target_offering',
}

export const ORDER_INFO_DRAWER_TAB_RUNS = 'orderInfoDrawerTabRuns';
