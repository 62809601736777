export enum EDigitalQueuePageType {
  booking = 'booking',
  schedule = 'schedule',
}

export enum EDigitalQueuePageView {
  timeline = 'timeline',
  table = 'table',
}

export enum EDigitalQueuePageTab {
  timeSlots = 'timeSlots',
  scheduleTemplates = 'scheduleTemplates',
}

export enum EReservedSlotType {
  locking = 'locking',
  reservation = 'reservation',
}

export enum ETimeSlotActionType {
  booking = 'booking',
  reservation = 'reservation',
  locking = 'locking',
}
