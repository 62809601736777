import { ROUTE_TYPE } from '@/constants';
import { ROUTE_NAME } from '@/contexts/freightExecutionContext/domain/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { hasCarrierWorkplaceSubscription, hasExecuteRunsPermission } from '@/domains/permissions/subscription';
import { canBrowseExecutionRequestsByRole } from '@/domains/permissions/role/executionRequests';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

const hasExecuteRunsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCarrierWorkplaceSubscription()
    : hasExecuteRunsPermission());

const routes = [
  buildProtectedRoute({
    path: '/execution_requests',
    name: ROUTE_NAME.executionRequests,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        canBrowseExecutionRequestsByRole,
        hasExecuteRunsSubscription,
      ],
      component: () => import('@/contexts/freightExecutionContext/views/ExecutionRequestsListView/index.vue'),
    },
  }),
];

export default routes;
