import useWebsocketChannel from '@/composables/useWebsocketChannel';
import { TWsChannelSubscribers } from '@/websocket/types';

export enum EViewSettingsWSEvents {
  viewSettingMaxColumnsUpdated = 'Events::PlatformContext::ViewSettingMaxColumnsUpdated',
}

export const useTenantSettingsWsChannel = (subscribers: TWsChannelSubscribers) => {
  const channelName = 'TenantSettingsChannel';

  useWebsocketChannel({
    channelName,
    subscribers,
  });
};
