import {
  Ref,
  computed,
} from 'vue';

import { EStatusType } from '@/domains/constants';

type TUseView = {
  hasBackground: Ref<boolean>,
  tooltip: Ref<string>,
  type: Ref<EStatusType>,
};

export const useView = ({
  hasBackground,
  tooltip,
  type,
}: TUseView) => {
  const extraClasses = computed(() => (hasBackground.value ? `status_has-background status_${type.value}` : ''));
  const isTooltipVisible = computed(() => !!tooltip.value && !hasBackground.value);

  return {
    extraClasses,
    isTooltipVisible,
  };
};
