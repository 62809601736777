<template>
  <PaywallMessage>
    <template #text>
      <template v-if="subscriptionWithPendingStatus || subscriptionRequest">
        <template v-if="hasCargoOwnerSubscription">
          <p v-if="subscriptionWithPendingStatus">
            {{ tt('billing.paywallMessage.yourPlanForCargoOwnerWillStartFrom',
                  {
                    param: {
                      date: expectedSubscriptionDate,
                    }
                  }) }}
          </p>
          <p v-else>
            {{ tt('billing.paywallMessage.connectionRequestHasBeenSuccessfullyCompleted') }}
          </p>
        </template>
        <p v-else>
          {{ capitalize(tt('shared.selected.masculine')) }}
          <span>{{ shortPlan && tt(`billing.planCode.${shortPlan}`) }}</span>
          {{ tt('billing.plan') }}.
          {{ tt('billing.paywallMessage.willStartWorking') }}
          {{ tt('shared.since') }}
          <span>{{ expectedSubscriptionDate }}</span>
        </p>
      </template>
      <template v-else-if="hasCarrierStartSubscription">
        <p>
          {{ tt('billing.paywallMessage.infoForCarrierStartSubscription') }}
        </p>
      </template>
      <template v-else-if="сanCreateSubscriptions">
        <p v-if="!isBillingValidFrom1MarchAvailable">
          {{ tt('billing.paywallMessage.freePeriodIsEnding') }}
          {{ capitalize(tt('shared.since')) }}
          <span>{{ expectedSubscriptionDate }}</span>
          {{ tt('billing.paywallMessage.ourServiceToolsAreBecomingPaid') }}
          {{ tt('billing.paywallMessage.youCanChooseAPlanInAdvance') }}
        </p>
        <p v-else>
          {{ tt('billing.paywallMessage.connectToOneOfThePlansToGetAccessToTheTools') }}
        </p>
      </template>
      <p v-else>
        {{ tt('billing.paywallMessage.theToolsOfOurServiceArePaid') }}.
        {{ tt('billing.paywallMessage.theOrganizationAdministratorCanChooseAPlan') }}
      </p>
    </template>
    <template #action>
      <Button
        v-if="showPlanSelectionButton"
        :type="EButtonType.primary"
        :size="ESize.small"
        :title="tt('billing.choosePlan.short')"
        @click="openModal"
      />
    </template>
  </PaywallMessage>

  <BillingPlanSelectionModal
    :visible="isOpenModal"
    @update:visible="isOpenModal = $event"
  />
</template>

<script lang="ts">

import {
  defineComponent,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import { capitalize } from '@/utils';
import BillingPlanSelectionModal from '@/contexts/billingContext/components/BillingPlanSelectionModal/index.vue';
import { formatDate } from '@/utils/dateUtils';
import { DATE_FORMAT } from '@/constants';
import PaywallMessage from '@/components/PaywallMessage/index.vue';
import { Button } from '@/ui';
import { EButtonType, ESize } from '@/ui/types';

import { useView } from './application/useView';

export default defineComponent({
  name: 'BillingPaywallMessage',
  components: {
    Button,
    BillingPlanSelectionModal,
    PaywallMessage,
  },
  setup() {
    const isOpenModal = ref<boolean>(false);

    const {
      showPlanSelectionButton,
      сanCreateSubscriptions,
      subscriptionRequest,
      shortPlan,
      isBillingValidFrom1MarchAvailable,
      hasCargoOwnerSubscription,
      expectedSubscriptionDate,
      subscriptionWithPendingStatus,
      hasCarrierStartSubscription,
    } = useView();

    const openModal = () => {
      isOpenModal.value = true;
    };

    return {
      showPlanSelectionButton,
      сanCreateSubscriptions,
      isOpenModal,
      subscriptionRequest,
      shortPlan,
      isBillingValidFrom1MarchAvailable,
      hasCargoOwnerSubscription,
      expectedSubscriptionDate,
      subscriptionWithPendingStatus,
      hasCarrierStartSubscription,
      DATE_FORMAT,
      EButtonType,
      ESize,

      tt,
      capitalize,
      openModal,
      formatDate,
    };
  },
});
</script>
