import { ROUTE_TYPE } from '@/constants';
import {
  hasMakeFreightRequestsPermission,
  hasOrganizeLookupsPermission,
  hasExecuteRunsPermission,
  hasParticipateInLookupsPermission,
  hasCarrierWorkplaceSubscription,
  hasCargoOwnerWorkplaceSubscription,
  hasForwarderFeaturesSubscription,
} from '@/domains/permissions/subscription';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { canBrowseLookupStrategiesByRole } from '@/domains/permissions/role/lookupStrategies';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

const hasExecuteRunsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCarrierWorkplaceSubscription()
    : hasExecuteRunsPermission());

const hasMakeFreightRequestsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    : hasMakeFreightRequestsPermission());

const hasOrganizeLookupsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription() || hasForwarderFeaturesSubscription()
    : hasOrganizeLookupsPermission());

const hasParticipateInLookupsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCarrierWorkplaceSubscription()
    : hasParticipateInLookupsPermission());

const routes = [
  buildProtectedRoute({
    path: '/executors_lookups/feed',
    name: 'ExecutorsLookupsFeed',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [hasExecuteRunsSubscription, hasParticipateInLookupsSubscription],
      component: () => import('@/contexts/executorsLookupContext/views/ExecutorsLookupsFeedView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/lookup_strategies',
    name: 'lookupStrategiesList',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        hasMakeFreightRequestsSubscription,
        hasOrganizeLookupsSubscription,
        canBrowseLookupStrategiesByRole,
      ],
      component: () => import('@/contexts/executorsLookupContext/views/LookupStrategiesListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/lookup_strategies/:id',
    name: 'LookupStrategyShowView',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        hasMakeFreightRequestsSubscription,
        hasOrganizeLookupsSubscription,
        canBrowseLookupStrategiesByRole,
      ],
      component: () => import('@/contexts/executorsLookupContext/views/LookupStrategyShowView/index.vue'),
    },
  }),
];

export default routes;
