<template>
  <Loadable
    :isLoading="isLoading"
    :isDone="isDone"
  >
    <div class="billing-carrier-form">
      <Alert
        v-if="isManyEmployeesAlertShown"
        type="error"
        class="billing-carrier-form__alert billing-carrier-form__alert-error"
        showIcon
      >
        <template #description>
          {{ tt('billing.alerts.manyEmployees') }}

          <SmartLink href="/employees" @click="$emit('close')">
            {{ tt('shared.toListEmployees') }}
          </SmartLink>
        </template>

        <template #icon>
          <WarningSvg />
        </template>
      </Alert>

      <Form
        class="billing-carrier-form__form"
        :model="formData"
        :layout="ELayout.vertical"
        autocomplete="off"
      >
        <div class="billing-carrier-form__subscription-date-block">
          <p class="billing-carrier-form__subscription-date-block-title">
            {{ getTextAboutConnectedPlan({
              planCode: selectedPlan?.planCode || null,
              withoutTariff: true,
              withoutPrefix: true,
            }) }}
          </p>
          <FormItem
            name="subscriptionAt"
            v-bind="validateInfos.subscriptionAt"
            :label="tt('shared.activationDate')"
          >
            <div class="billing-carrier-form__about-subscription-block">
              <DatePicker
                v-model:value="formData.subscriptionAt"
                class="billing-carrier-form__data-picker"
                :format="DATE_FORMAT.fullDate"
                :valueFormat="DATE_FORMAT.fullDate"
                :disabled="!isBillingValidFrom1MarchAvailable"
                :disabledDate="(date) => getIsDisabledRangeForDate(date, 60)"
                @change="handleFieldChange('subscriptionAt')"
              />
              <div class="billing-carrier-form__about-subscription-block-info">
                <p>
                  <span>
                    {{ formatNumberAsCurrency(selectedPlan?.price, 0, {
                      separator: ' ',
                      symbol: tt('shared.currency.symbol.rub'),
                      pattern: '# !'
                    }) }}
                  </span>
                  /{{ tt('shared.shortMonth') }}
                </p>
                <SmartLink :href="MORE_INFO_ABOUT_PLANS_URL" alwaysOpenNewTab>
                  {{ tt('billing.aboutTerms') }}
                </SmartLink>
              </div>
            </div>
            <div
              v-if="isBillingTrialPeriodInfoShown"
              class="billing-carrier-form__trial-period-info"
            >
              {{ `${tt('shared.first.plural')} ${tt('billing.firstDaysIsTrial', {
                param: {
                  count: COUNT_OF_BILLING_TRIAL_PERIOD_DAYS,
                }
              })}` }}
              <Tooltip
                :title="tt('billing.modal.infoAboutTrialPeriod')"
                :placement="EPopoverPlacement.bottom"
              >
                <InfoSmallSvg />
              </Tooltip>
            </div>
          </FormItem>
        </div>

        <div class="billing-carrier-form__row">
          <FormItem
            name="bankIdentificationCode"
            v-bind="validateInfos.bankIdentificationCode"
            :label="tt('shared.bankBik')"
          >
            <Select
              :value="formData.bankIdentificationCode"
              :options="bankIdentificationCodes"
              :size="ESize.medium"
              :isInvalid="isFieldInvalid('bankIdentificationCode')"
              @select="setValue('bankIdentificationCode', $event)"
              @update:value="handleSearch"
            />
          </FormItem>

          <FormItem
            name="bankAccountNumber"
            v-bind="validateInfos.bankAccountNumber"
            :label="tt('shared.checkingAccount')"
          >
            <Input
              v-model:value="formData.bankAccountNumber"
              isDigital
              @input="handleFieldChange('bankAccountNumber')"
            />
          </FormItem>
        </div>

        <div class="billing-carrier-form__row">
          <FormItem
            name="organizationAddress"
            v-bind="validateInfos.organizationAddress"
            :label="tt('shared.tenantLegalAddress')"
          >
            <Input
              v-model:value="formData.organizationAddress"
              @input="handleFieldChange('organizationAddress')"
            />
          </FormItem>

          <FormItem
            name="organizationPhone"
            v-bind="validateInfos.organizationPhone"
            :label="tt('shared.tenantPhone')"
          >
            <PhoneInput
              v-model:value="formData.organizationPhone"
              hasMask
              @input="handleFieldChange('organizationPhone')"
            />
          </FormItem>
        </div>

        <FormItem
          name="invoicingEmail"
          :label="tt('shared.invoicingEmail')"
          v-bind="validateInfos.invoicingEmail"
        >
          <EmailConfirmation
            :email="formData.invoicingEmail || ''"
            :isConfirmationRequired="!isEmailConfirmed"
            :requestCodeFunction="requestConfirmationCode"
            :confirmCodeFunction="confirmCodeForEmail"
            @confirmed="handleEmailConfirmed"
          >
            <div class="billing-carrier-form__email-confirmation-wrapper">
              <Input
                v-model:value="formData.invoicingEmail"
                :disabled="isEmailConfirmed"
                @input="handleFieldChange('invoicingEmail')"
              />
              <DeleteSvg
                v-if="isEmailConfirmed"
                class="billing-carrier-form__delete-icon"
                @click="handleChangeEmail"
              />
            </div>
            <CheckColoredSvg v-if="isEmailConfirmed" />
          </EmailConfirmation>
        </FormItem>

        <FormItem
          name="edmAvailable"
          class="billing-carrier-form__form-item"
          @click="setValue('edmAvailable', !formData.edmAvailable)"
        >
          <div class="billing-carrier-form__toggle-wrapper">
            <Toggle class="billing-carrier-form__toggle" :checked="formData.edmAvailable" />

            <div>
              <span class="billing-carrier-form__toggle-hint billing-carrier-form__toggle-hint-title">
                {{ tt('shared.documentManagement.sendDocsByElectronics') }}
              </span>

              <span
                class="billing-carrier-form__toggle-hint billing-carrier-form__toggle-hint-subtitle"
              >
                {{ tt('shared.documentManagement.sendInvitationToPartnership') }}
              </span>
            </div>
          </div>
        </FormItem>

        <FormItem
          name="publicOfferAccept"
          class="billing-carrier-form__form-item"
        >
          <Checkbox
            v-model:checked="formData.publicOfferAccept"
          >
            {{ `${tt('shared.readAndAccept')} ${ tt('shared.licensed')}` }}

            <SmartLink
              :href="CARRIER_PUBLIC_OFFER_LINK"
              alwaysOpenNewTab
            >
              {{ tt('shared.offerContract') }}
            </SmartLink>
          </Checkbox>
        </FormItem>
      </Form>
    </div>
  </Loadable>
</template>

<script lang="ts">

import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  toRefs,
} from 'vue';
import {
  Form,
  FormItem,
  DatePicker,
  Alert,
} from 'ant-design-vue';

import CheckColoredSvg from '@/assets/svg/16x16/check-colored.svg';
import DeleteSvg from '@/assets/svg/16x16/delete.svg';
import WarningSvg from '@/assets/svg/20x20/warning.svg';
import tt from '@/i18n/utils/translateText';
import {
  Checkbox,
  Input,
  PhoneInput,
  Toggle,
  Select,
  Tooltip,
} from '@/ui';
import { EPopoverPlacement, ESize } from '@/ui/types';
import {
  ELayout,
  DATE_FORMAT,
} from '@/constants';
import InfoSmallSvg from '@/assets/svg/16x16/info.svg';
import SmartLink from '@/components/SmartLink/index.vue';
import Loadable from '@/components/Loadable/index.vue';
import EmailConfirmation from '@/components/EmailConfirmation/index.vue';
import { confirmCodeForEmail, requestConfirmationCode } from '@/contexts/billingContext/services';
import {
  MORE_INFO_ABOUT_PLANS_URL,
  COUNT_OF_BILLING_TRIAL_PERIOD_DAYS,
  CARRIER_PUBLIC_OFFER_LINK,
} from '@/domains/constants';
import { getIsDisabledRangeForDate } from '@/utils/dateUtils';
import { TBillingAvailablePlan } from '@/contexts/billingContext/domain/types';
import { formatNumberAsCurrency } from '@/utils';
import { getTextAboutConnectedPlan } from '@/contexts/billingContext/domain/getTextAboutConnectedPlan';

import { useInteractWithForm } from './application/useInteractWithForm';
import { useFetchData } from './application/useFetchData';
import { useView } from './application/useView';
import { useSearchBankIdentificationCodes } from './application/useSearchBankIdentificationCodes';

export default defineComponent({
  name: 'BillingCarrierForm',
  components: {
    SmartLink,
    Form,
    FormItem,
    Input,
    PhoneInput,
    DatePicker,
    Toggle,
    Checkbox,
    Loadable,
    EmailConfirmation,
    Select,
    CheckColoredSvg,
    Tooltip,
    InfoSmallSvg,
    DeleteSvg,
    Alert,
    WarningSvg,
  },
  props: {
    selectedPlan: {
      type: [Object, null] as PropType<TBillingAvailablePlan | null>,
      default: null,
    },
  },
  emits: ['close', 'goPreviousStep'],
  setup(props, { emit, expose }) {
    const { selectedPlan } = toRefs(props);

    const planCode = computed(() => selectedPlan.value?.planCode || null);

    const {
      isLoading,
      isDone,
      isManyEmployeesAlertShown,
      bankAccountInfo,

      fetchDataForSetForm,
    } = useFetchData(planCode, emit);

    const {
      formData,
      isPublicOfferAccept,
      isEmailConfirmed,
      validateInfos,

      isFieldInvalid,
      handleFieldChange,
      handleEmailConfirmed,
      handleChangeEmail,
      onSubmit,
      setValue,
      setResponseErrors,
    } = useInteractWithForm(bankAccountInfo);

    const {
      isSubmitDisabled,
      isBillingValidFrom1MarchAvailable,
      isBillingTrialPeriodInfoShown,
    } = useView({
      isPublicOfferAccept,
      isManyEmployeesAlertShown,
      isEmailConfirmed,
      planCode,
    });

    const {
      bankIdentificationCodes,

      handleSearch,
    } = useSearchBankIdentificationCodes(
      handleFieldChange,
      setValue,
    );

    expose({
      isSubmitDisabled,

      onSubmit,
      setResponseErrors,
    });

    onMounted(() => {
      fetchDataForSetForm();
    });

    return {
      formData,
      isLoading,
      isDone,
      isManyEmployeesAlertShown,
      isSubmitDisabled,
      isEmailConfirmed,
      ELayout,
      DATE_FORMAT,
      ESize,
      bankIdentificationCodes,
      MORE_INFO_ABOUT_PLANS_URL,
      COUNT_OF_BILLING_TRIAL_PERIOD_DAYS,
      CARRIER_PUBLIC_OFFER_LINK,
      validateInfos,
      isBillingValidFrom1MarchAvailable,
      isBillingTrialPeriodInfoShown,
      EPopoverPlacement,

      tt,
      setValue,
      handleFieldChange,
      handleEmailConfirmed,
      handleChangeEmail,
      requestConfirmationCode,
      confirmCodeForEmail,
      onSubmit,
      handleSearch,
      setResponseErrors,
      isFieldInvalid,
      getIsDisabledRangeForDate,
      formatNumberAsCurrency,
      getTextAboutConnectedPlan,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
