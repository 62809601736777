import { ROUTE_TYPE } from '@/constants';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasExtraServicesPermission,
} from '@/domains/permissions/subscription';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import { ROUTE_NAME } from '../domains/constants';

const hasExtraServicesSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    : hasExtraServicesPermission());

const routes = [
  buildProtectedRoute({
    path: '/extra_services',
    name: ROUTE_NAME.extraServices,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [hasExtraServicesSubscription],
      component: () => import('@/contexts/extraServicesContext/views/ExtraServicesView/index.vue'),
    },
  }),
];

export default routes;
