import { EXECUTORS_LOOKUPS_FEED_VIEW_FILTER_SELECT_NAME }
  from '@/contexts/executorsLookupContext/views/ExecutorsLookupsFeedView/constants';
import {
  EOperationalDocumentsFlowFilters,
  OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_STATUS,
  OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_TYPE,
} from '@/contexts/operationalDocumentsContext/views/OperationalDocumentsListView/domain/constants';
import { ORDERS_FILTER_SELECT_DEPARTMENTS }
  from '@/contexts/orderingContext/views/OrdersListView/components/OrdersListOnlyOrders/components/OrdersDepartmentsFilter/constants';
import { ORDERS_FILTER_SELECT_INCIDENTS }
  from '@/contexts/orderingContext/views/OrdersListView/components/OrdersListOnlyOrders/components/OrdersIncidentsFilters/constants';
import {
  EXECUTION_REQUEST_TABLE_FILTER_SELECT_QUANTITY,
  EXECUTION_REQUEST_TABLE_FILTER_SELECT_ASSIGNED_QUANTITY,
  EXECUTION_REQUEST_TABLE_FILTER_SELECT_LOOKUPS_STATE,
  EXECUTION_REQUEST_TABLE_FILTER_SELECT_TYPE,
} from '@/contexts/freightExecutionContext/constants';
import { EPartnershipResourcesSubSections } from '@/contexts/partnershipsContext/views/PartnershipResourcesView/domain/constants';

import { STORE_FILTER_SUB_SECTION_GLOBAL } from './constants';
import { TFilterState } from './types';

const createDefaultCommonFilterState = () => ({
  currentAppliedSet: null,
  currentAppliedPreset: null,
  currentEditablePreset: null,
});

const createDefaultFilterSelectState = () => ({ current: null });

const createDefaultFilterSelectWithIdState = () => ({
  current: null,
  id: null,
});

export const initialState = (): TFilterState => ({
  executorsLookupsFeedView: {
    new: {
      filter: createDefaultCommonFilterState(),
      [EXECUTORS_LOOKUPS_FEED_VIEW_FILTER_SELECT_NAME]: createDefaultFilterSelectState(),
    },
    bidding: {
      filter: createDefaultCommonFilterState(),
      [EXECUTORS_LOOKUPS_FEED_VIEW_FILTER_SELECT_NAME]: createDefaultFilterSelectState(),
    },
    finished: {
      filter: createDefaultCommonFilterState(),
      [EXECUTORS_LOOKUPS_FEED_VIEW_FILTER_SELECT_NAME]: createDefaultFilterSelectState(),
    },
  },
  operationalDocumentsView: {
    [EOperationalDocumentsFlowFilters.all]: {
      filter: createDefaultCommonFilterState(),
      [OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_STATUS]: createDefaultFilterSelectState(),
      [OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_TYPE]: createDefaultFilterSelectState(),
    },
    [EOperationalDocumentsFlowFilters.inbox]: {
      filter: createDefaultCommonFilterState(),
      [OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_STATUS]: createDefaultFilterSelectState(),
      [OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_TYPE]: createDefaultFilterSelectState(),
    },
    [EOperationalDocumentsFlowFilters.outbox]: {
      filter: createDefaultCommonFilterState(),
      [OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_STATUS]: createDefaultFilterSelectState(),
      [OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_TYPE]: createDefaultFilterSelectState(),
    },
    [EOperationalDocumentsFlowFilters.drafts]: {
      filter: createDefaultCommonFilterState(),
      [OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_STATUS]: createDefaultFilterSelectState(),
      [OPERATIONAL_DOCUMENTS_VIEW_FILTER_SELECT_TYPE]: createDefaultFilterSelectState(),
    },
  },
  // subSection сетятся динамически через setSubsections
  matricesListView: {},
  referencesListView: {},
  executionRequestsView: {
    [STORE_FILTER_SUB_SECTION_GLOBAL]: {
      filter: createDefaultCommonFilterState(),
      [EXECUTION_REQUEST_TABLE_FILTER_SELECT_TYPE]: createDefaultFilterSelectState(),
      [EXECUTION_REQUEST_TABLE_FILTER_SELECT_QUANTITY]: createDefaultFilterSelectState(),
      [EXECUTION_REQUEST_TABLE_FILTER_SELECT_ASSIGNED_QUANTITY]: createDefaultFilterSelectState(),
      [EXECUTION_REQUEST_TABLE_FILTER_SELECT_LOOKUPS_STATE]: createDefaultFilterSelectState(),
    },
  },
  orders: {
    [STORE_FILTER_SUB_SECTION_GLOBAL]: { [ORDERS_FILTER_SELECT_DEPARTMENTS]: createDefaultFilterSelectWithIdState() },
    finalized: {
      filter: createDefaultCommonFilterState(),
      [ORDERS_FILTER_SELECT_INCIDENTS]: createDefaultFilterSelectState(),
    },
    onBidding: {
      filter: createDefaultCommonFilterState(),
      [ORDERS_FILTER_SELECT_INCIDENTS]: createDefaultFilterSelectState(),
    },
    onExecution: {
      filter: createDefaultCommonFilterState(),
      [ORDERS_FILTER_SELECT_INCIDENTS]: createDefaultFilterSelectState(),
    },
    onTargetOffering: {
      filter: createDefaultCommonFilterState(),
      [ORDERS_FILTER_SELECT_INCIDENTS]: createDefaultFilterSelectState(),
    },
    pending: {
      filter: createDefaultCommonFilterState(),
      [ORDERS_FILTER_SELECT_INCIDENTS]: createDefaultFilterSelectState(),
    },
  },
  shipments: { [STORE_FILTER_SUB_SECTION_GLOBAL]: { filter: createDefaultCommonFilterState() } },
  executionRequestPanelTabRuns: { [STORE_FILTER_SUB_SECTION_GLOBAL]: { filter: createDefaultCommonFilterState() } },
  orderInfoDrawerTabRuns: { [STORE_FILTER_SUB_SECTION_GLOBAL]: { filter: createDefaultCommonFilterState() } },
  partnershipResources: {
    [EPartnershipResourcesSubSections.vehicles]: { filter: createDefaultCommonFilterState() },
    [EPartnershipResourcesSubSections.drivers]: { filter: createDefaultCommonFilterState() },
  },
  employeesResponsible: { [STORE_FILTER_SUB_SECTION_GLOBAL]: { filter: createDefaultCommonFilterState() } },
});
