import { ROUTE_PERMISSION_REJECTION_REASON } from '@/constants';

type THasPermissionsChecker = () => boolean;

const checkRoutePermissions = (route: any) => {
  const hasPermissions = route.meta.permissions?.length > 0;

  if (!hasPermissions) return Promise.resolve();

  /**
   * Если хотя бы одно из разрешений не имеется, то есть hasPermissionsChecker возвращает false,
   * то доступ к роуту не предоставляется. Здесь реализована логика проверки прав через логическое И.
   * При необходимости проверять наличие хотя бы одного доступа из нескольких, то нужно
   * либо доработать эту функцию, чтобы она умела принимать логическую операцию И / ИЛИ,
   * либо на уровне выше определять функцию проверки, которая будет в себе реализовывать операцию ИЛИ.
   * Например,
   * 1. проверка на наличие executeRunsPermission И makeFreightRequestsPermission
   * permissions: [hasExecuteRunsPermission, hasMakeFreightRequestsPermission]
   * 2. проверка на наличие executeRunsPermission ИЛИ makeFreightRequestsPermission
   * permissions: [() => (hasExecuteRunsPermission() || hasMakeFreightRequestsPermission())]
   */
  const isAcceptedPermission = !route.meta.permissions.some((hasPermissionsChecker: THasPermissionsChecker) => {
    let isPermissionDenied = true;
    if (typeof hasPermissionsChecker === 'function') {
      isPermissionDenied = !hasPermissionsChecker();
    }
    return isPermissionDenied;
  });

  if (isAcceptedPermission) return Promise.resolve();

  return Promise.reject(ROUTE_PERMISSION_REJECTION_REASON.unaccepted);
};

export default checkRoutePermissions;
