/** Отступ при скролле к выбранному месяцу, позволяет скрыть заголовок у элемента за пределами области просмотра */
export const SCROLL_OFFSET_FOR_SELECTED_MONTH = 40;

/**
 * Максимальная высота элемента в ленте месяцев, соответствует месяцу, первые и последние дни которого, попадают на последнюю неделю предыдущего и первую неделю следующего месяцев.
 * Как пример, сентябрь 2024. 1 число выпадает на воскресенье предыдущего месяца, 30 выпадает на понедельник следующего месяца.
 * Сформирован следующим образом: 48 px заголовок + 6 строк с неделями по 32 px
*/
export const MAX_MONTH_ELEMENT_HEIGHT = 250;

/** Количество сформированных (отрисованных) элементов в ленте месяцев */
export const COUNT_MONTH_ELEMENTS = 5;

/** Максимальная доля пересечения наблюдаемым элементом области просмотра (наблюдения) */
export const MAX_INTERSECTION_RATIO = 1;

/**
 * Строка с отступами для области просмотра (наблюдения). Синтаксис соответствует CSS свойству margin. Единицы измерения обязательны.
 *
 * Смещаем область просмотра на 10 пикселей вниз (из-за наличия у компонента снизу пустого пространства),
 * чтобы смена месяцев при скролле состоялась тогда, когда визуально скроется/появится сверху месяц.
 *
 * Расчет производился следующим образом: высота строки с днями недели 32px, шрифт 14. (32 - 14) / 2 = 9, округление до 10. Отрицательный отступ,
 * так как смещаем вниз.
*/
export const INTERSECTION_OBSERVER_ROOT_MARGIN = '-10px 0px 10px';

/**
 * Порог пересечения наблюдаемым элементом области просмотра, при котором будет срабатывать вызов коллбэка:
 *
 * 0 - сразу после добавления в наблюдаемые или когда наблюдаемый элемент вышел за пределы области наблюдения;
 * 0.5 - когда наблюдаемый пересекает область просмотра на 50% (необходимо при быстром скролле). Детальное описание в документации (docs/development/implementation-details/date-picker.md);
 * 1 - когда полностью находится в области просмотра.
*/
export const INTERSECTION_OBSERVER_THRESHOLD = [0, 0.5, 1];
