import { getActualAuthToken } from '@/api/sendApiRequest';
import logger from '@/logger';
import store from '@/store';

import {
  connectConsumer,
  closeConnection,
  getConsumerInstance,
  getCurrentTenantId,
  getUrl,
} from './index';
import { addTaskToStack } from './tasksStack';

export const openWsConnection = () => {
  getConsumerInstance();
};

const setUrlToConsumer = async () => {
  const consumer = await getConsumerInstance();
  const tokens = await getActualAuthToken() as any;
  const tenantId = getCurrentTenantId();

  if (!tokens?.accessToken || !tenantId) {
    const message = '[setUrlToConsumer] accessToken or tenantId is undefined. Logout.';

    logger.warn(message);
    throw new Error(message);
  }

  consumer._url = getUrl({
    tenantId,
    token: tokens?.accessToken || '',
  });
};

export const disconnectConsumer = async () => {
  const consumer = await getConsumerInstance();

  if (consumer) {
    consumer.disconnect();
  }
};

export const reconnectWithNewUrl = async () => {
  const removeTask = addTaskToStack();

  try {
    await disconnectConsumer();
    await setUrlToConsumer();
    await connectConsumer();

    removeTask();
  } catch {
    await closeConnection();
    logger.log('[reconnectWithNewUrl] setUrlToConsumer().catch');
    store.dispatch('app/logout');
  }
};
