import { useRoute } from 'vue-router';

import { EExperimentalFeatures } from '@/domains/constants';
import store from '@/store';
import { ROUTE_TYPE } from '@/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';

export const getIsLicenseRestrictionLayoutShown = (): boolean => {
  const route = useRoute();
  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;

  const isLicenseSettingsForInstanceWithoutBillingAvailable = checkFeatureIsEnabled(
    EExperimentalFeatures.licenseSettingsForInstanceWithoutBilling,
  );

  const hasTenants = store.state.tenants.userTenants?.length > 0;

  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;
  const hasLicenseRole = !!store.state.tenants.currentTenantInfo?.licenseRole;

  return isLicenseSettingsForInstanceWithoutBillingAvailable
    && hasTenants
    && isPrivateRoute
    && !route.path.startsWith('/employees')
    && !route.path.startsWith('/tenant/settings')
    && !route.path.startsWith('/account')
    && !route.path.startsWith('/invitations')
    && hasInstanceLicense
    && !hasLicenseRole;
};
