import { computed, watch } from 'vue';
import * as Sentry from '@sentry/vue';

import store from '@/store';

export const useAppSentry = () => {
  const sentryDsn = import.meta.env.VITE_APP_SENTRY_DSN || null;
  const environment = import.meta.env.VITE_APP_SENTRY_ENVIRONMENT || null;
  const replaysSessionSampleRate = import.meta.env.VITE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || null;
  const replaysOnErrorSampleRate = import.meta.env.VITE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || null;

  const setUserForSentry = () => {
    const currentTenantId = computed(() => store.state.tenants.currentTenant?.id);
    const currentUserId = computed(() => store.state.user.data?.id);
    const currentUserEmail = computed(() => store.state.user.data?.email);

    watch([currentTenantId, currentUserId, currentUserEmail], ([tenantId, userId, userEmail]) => {
      if (tenantId && userId && userEmail) {
        Sentry.setUser({
          id: `tenantId: ${tenantId}, userId: ${userId}`,
          username: userEmail,
        });
      }
    });
  };

  const sentryInit = (app) => {
    if (sentryDsn) {
      if (environment === 'production') {
        Sentry.init({
          app,
          logErrors: true,
          dsn: sentryDsn,
          environment,
          tracesSampleRate: 1.0,
          replaysSessionSampleRate: Number(replaysSessionSampleRate),
          replaysOnErrorSampleRate: Number(replaysOnErrorSampleRate),
          integrations: [
            Sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
              minReplayDuration: 15000,
              networkDetailAllowUrls: [/^https:\/\/api-tms-(.*?)\.stage\.trucker\.group\/(.*?)/, 'api.tms.ru'],
            }),
          ],
          ignoreErrors: [
            'Failed to fetch dynamically imported module', // игнорируем ошибку vite из роутера
          ],
        });
      } else {
        Sentry.init({
          app,
          logErrors: true,
          dsn: sentryDsn,
          environment,
          tracesSampleRate: 1.0,
          ignoreErrors: [
            'Failed to fetch dynamically imported module', // игнорируем ошибку vite из роутера
          ],
        });
      }
    }
  };

  return {
    setUserForSentry,
    sentryInit,
  };
};
