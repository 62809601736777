import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import checkRolePermissions from '@/domains/permissions/checkRolePermissions';

import canBrowseOrdersPendingByRole from './canBrowseOrdersPendingByRole';
import canBrowseOrdersOnBiddingByRole from './canBrowseOrdersOnBiddingByRole';
import canBrowseOrdersOnTargetOfferingByRole from './canBrowseOrdersOnTargetOfferingByRole';
import canBrowseOrdersOnExecutionByRole from './canBrowseOrdersOnExecutionByRole';
import canBrowseOrdersFinalizedByRole from './canBrowseOrdersFinalizedByRole';

const canBrowseOrdersSectionByRole = () => {
  // Доступ к разделу заказы определяется наличием доступа хотя бы к одной папке
  if (checkFeatureIsEnabled(EExperimentalFeatures.equalizeOrdersStatesToFolders)) {
    return canBrowseOrdersPendingByRole()
    || canBrowseOrdersOnBiddingByRole()
    || canBrowseOrdersOnTargetOfferingByRole()
    || canBrowseOrdersOnExecutionByRole()
    || canBrowseOrdersFinalizedByRole();
  }

  // TODO: удалить после выпила ФФ equalizeOrdersStatesToFolders
  const result = checkRolePermissions(['orders.browseSection']);
  return result.passed;
};

export default canBrowseOrdersSectionByRole;
