import { ROUTE_TYPE } from '@/constants';
import { ROUTE_NAME } from '@/contexts/operationalDocumentsContext/domain/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
  hasOperationalDocumentsPermission,
  hasShipperWorkplaceSubscription,
  hasOperationalDocumentsSubscription,

} from '@/domains/permissions/subscription';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import isTenantVerified from '@/domains/isTenantVerified';

// После удаления FF licenseSettingsForInstanceWithoutBilling и billingValidFrom1March
// останется return hasOperationalDocumentsSubscription();
const hasOperationalDocumentsSubscriptions = () => {
  if (checkFeatureIsEnabled(EExperimentalFeatures.licenseSettingsForInstanceWithoutBilling)) {
    return hasOperationalDocumentsSubscription();
  }
  if (checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)) {
    return hasCarrierWorkplaceSubscription()
      || hasCargoOwnerWorkplaceSubscription()
      || hasShipperWorkplaceSubscription();
  }
  return hasOperationalDocumentsPermission();
};

const routes = [
  buildProtectedRoute({
    path: '/operational_documents',
    name: ROUTE_NAME.operationalDocumentsList,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        hasOperationalDocumentsSubscriptions,
        isTenantVerified,
      ],
      component: () => import('@/contexts/operationalDocumentsContext/views/OperationalDocumentsListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/operational_documents/constructor/:id',
    name: ROUTE_NAME.operationalDocumentsConstructor,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        hasOperationalDocumentsSubscriptions,
        isTenantVerified,
      ],
      component: () => import('@/contexts/operationalDocumentsContext/views/OperationalDocumentsConstructorView/index.vue'),
    },
  }),
];

export default routes;
