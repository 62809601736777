import { Ref, computed } from 'vue';

import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import {
  CARGO_OWNER_PUBLIC_OFFER_LINK,
  CARRIER_PUBLIC_OFFER_LINK,
  EExperimentalFeatures,
} from '@/domains/constants';

export const useView = (planCode: Ref<EBillingPlanCode | null>) => {
  const isCargoOwnerPlan = computed(() => planCode.value === EBillingPlanCode.cargoOwner);

  const publicOfferLink = computed(() => (
    isCargoOwnerPlan.value ? CARGO_OWNER_PUBLIC_OFFER_LINK : CARRIER_PUBLIC_OFFER_LINK),
  );

  const isBillingValidFrom1MarchAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March));

  return {
    publicOfferLink,
    isBillingValidFrom1MarchAvailable,
  };
};
