import {
  computed,
  watch,
  onUnmounted,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';

import logger from '@/logger';
import { createEventBus, TFn } from '@/utils/eventBus';
import { isObject } from '@/utils';
import { createChannel } from '@/websocket';
import { TWSMessagePayload } from '@/websocket/types';
import { ROUTE_TYPE } from '@/constants';

type TSubscribers = Record<string, TFn>;
type TWebsocketChannelOptions = {
  onMountInitialization?: boolean,
};
export type TWebsocketChannelParam = {
  channelName: string,
  subscribers?: TSubscribers,
  payload?: Record<string, unknown>,
  options?: TWebsocketChannelOptions,
  onMessage?: TFn,
};

const getUniqEventPrefix = () => uuidv4();
/**
 * На один канал могут быть подписаны несколько слушателей, поэтому одно и то же событие
 * может быть опубликовано в globalEventBus, и тогда будут вызваны одни и те же обработчики несколько раз.
 * Чтобы такого не возникало, добавил уникальный ключ для каждого слушателя. Теперь даже если подписка происходит на один канал,
 * то события у каждого канала будут уникальными.
 * TODO: возможно это плохой подход. Можно попробовать сделать так, что при подписке на канал
 * сначала проверять нет ли уже активной подписки на канал. Если есть, то просто добавлять новые хэндлеры,
 * не создавая нового подписчика
 */
const getUniqEventKey = (eventKey: string, prefix: string) => `${eventKey}-${prefix}`;

const {
  subscribe,
  unsubscribe,
  emit,
} = createEventBus();

const useWebsocketChannel = ({
  channelName,
  subscribers = {},
  payload = {},
  options = { onMountInitialization: true },
  onMessage,
}: TWebsocketChannelParam) => {
  const eventPrefix = getUniqEventPrefix();
  const route = useRoute();

  const canConnectToWs = computed(() => options.onMountInitialization
    && route.meta.type
    && route.meta.type !== ROUTE_TYPE.unauthenticated
    && route.meta.type !== ROUTE_TYPE.public);

  const subscribeAll = () => {
    if (!isObject(subscribers)) {
      logger.warn(`[${channelName}] subscribers must be object.`);
      return;
    }

    /**
     * сообщения, пришедшие по WebSocket публикуются в eventBus.
     * На каждое событие подписывается какой-то конкретный слушатель/слушатели.
     */
    Object.entries(subscribers).forEach(([key, callback]) => {
      const uniqEventKey = getUniqEventKey(key, eventPrefix);

      subscribe(uniqEventKey, callback);
    });
  };

  const unsubscribeAll = () => {
    Object.entries(subscribers).forEach(([key, callback]) => {
      const uniqEventKey = getUniqEventKey(key, eventPrefix);
      unsubscribe(uniqEventKey, callback);
    });
  };

  const handleMessage = (messagePayload: TWSMessagePayload) => {
    if (onMessage) {
      onMessage(messagePayload);
    }
    const { type, payload } = messagePayload;
    if (!type) {
      logger.warn('[handleMessage] There is no event type in message. Message: ', messagePayload);
      return;
    }

    const isEventHasSubscribers = subscribers[type];
    if (!isEventHasSubscribers) return;

    const uniqEventKey = getUniqEventKey(type, eventPrefix);
    emit(uniqEventKey, payload);
  };

  let unsubscribePromise: Promise<void | (() => void)> | null = null;

  const connect = (connectPayload?: Record<string, unknown>) => {
    subscribeAll();
    unsubscribePromise = createChannel({
      channel: {
        channel: channelName,
        ...connectPayload,
      },
      onMessage: handleMessage,
    });
  };

  const unsubscribeFromWsChannel = () => unsubscribePromise?.then((unsubscribeFn) => {
    if (typeof unsubscribeFn === 'function') {
      unsubscribeFn();
    }
  });

  const closeConnection = async () => {
    try {
      await unsubscribeFromWsChannel();
      // после отписки удаляем объект коннекта к каналу, чтобы во время onUnmounted не дублировались отписки
      unsubscribePromise = null;
    } catch {
      logger.warn('[closeConnection] can not unsubscribe from ws channel');
    }
  };

  watch(() => route.meta.type, (_: unknown, oldType: unknown) => {
    if (canConnectToWs.value && !oldType) {
      connect(payload);
    }
  }, { immediate: true });

  onUnmounted(() => {
    // если соединение по сокету создается не на этапе onMounted, то
    // автоматически не отписываем от канала при onUnmounted так как соединение могло быть и не установлено
    if (canConnectToWs.value) {
      closeConnection();
      unsubscribeAll();
    }
  });

  return {
    unsubscribe,
    connect,
    closeConnection,
  };
};

export default useWebsocketChannel;
