import store from '@/store';
import { isObject } from '@/utils';

import { getPermissionsObject } from './permissions/checkRolePermissions';

const permissionsKeyStyle = 'background: lightblue; color: black;';
const permissionsValueStyle = 'background: gold; color: black; font-weight: bold;';

// Обновить после выпила billingValidFrom1March
const logSubscriptionFeatures = () => {
  const { currentTenant } = store.state.tenants;
  if (!currentTenant?.subscription?.features || !isObject(currentTenant.subscription.features)) return;
  // eslint-disable-next-line no-console
  console.groupCollapsed('%c Subscription features ', permissionsValueStyle);
  Object.entries(currentTenant.subscription.features).forEach(([key, value]) => {
    // eslint-disable-next-line no-console
    console.log(`%c ${key}: %c ${value} `, permissionsKeyStyle, permissionsValueStyle);
  });
  // eslint-disable-next-line no-console
  console.groupEnd();
};

const logUserInTenantPermissions = () => {
  const userPermissions = getPermissionsObject(store.state.tenants.currentTenant?.employee?.roles || []);
  if (!isObject(userPermissions)) return;
  // eslint-disable-next-line no-console
  console.groupCollapsed('%c User in tenant permissions ', permissionsValueStyle);
  Object.entries(userPermissions).forEach(([entity, entityPermissions]) => {
    // eslint-disable-next-line no-console
    console.groupCollapsed(`%c ${entity}: `, permissionsKeyStyle);
    Object.entries(entityPermissions as any).forEach(([action, value]) => {
      // eslint-disable-next-line no-console
      console.log(`%c ${action}: %c ${value} `, permissionsKeyStyle, permissionsValueStyle);
    });
    // eslint-disable-next-line no-console
    console.groupEnd();
  });
  // eslint-disable-next-line no-console
  console.groupEnd();
};

export const logPermissions = () => {
  logSubscriptionFeatures();
  logUserInTenantPermissions();
};
