import { EPermissionComparisonFunction } from '@/constants';
import store from '@/store';
import checkPermissionsCommon from '@/domains/permissions/checkPermissionsCommon';
import { TSubscriptionFeatures, TActiveSubscriptionsConditionsFeatures } from '@/types';

import { checkFeatureIsEnabled } from '../checkFeatureIsEnabled';
import { EExperimentalFeatures } from '../constants';

export type TSubscriptionPermissionItem = TFinalPathsOfObject<TSubscriptionFeatures>
| TFinalPathsOfObject<TActiveSubscriptionsConditionsFeatures>;

const checkSubscriptionPermissions = (
  subscriptionItems: TSubscriptionPermissionItem[],
  operator: EPermissionComparisonFunction = EPermissionComparisonFunction.AND,
) => {
  const isNewSubscriptionsFeaturesAvailable = checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March);

  const subscriptionFeatures = isNewSubscriptionsFeaturesAvailable
    ? store.state.tenants.currentTenant?.activeSubscriptionsConditions?.features
    : store.state.tenants.currentTenant?.subscription?.features;

  return checkPermissionsCommon<TSubscriptionPermissionItem[]>(subscriptionFeatures, subscriptionItems, operator);
};

export default checkSubscriptionPermissions;
