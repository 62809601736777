<template>
  <div
    class="status"
    :class="extraClasses"
  >
    <div
      v-if="hasIconSlot"
      test-id="status-icon-wrapper"
      :class="`status__icon status__icon_${type}`"
    >
      <slot name="icon" />
    </div>
    <div
      v-else
      test-id="status-dot"
      :class="`status__dot status__dot_${type}`"
    />
    <Tooltip
      v-if="text"
      :title="tooltip"
      :visible="isTooltipVisible"
    >
      <span
        class="status__text"
        :class="{ 'status__text_underline': isTooltipVisible }"
      >
        {{ text }}
      </span>
    </Tooltip>
    <slot />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  toRefs,
} from 'vue';

import { EStatusType } from '@/domains/constants';
import { useHasSlot } from '@/composables/useHasSlot';

import Tooltip from '../Tooltip/index.vue';
import { useView } from './application/useView';

export default defineComponent({
  name: 'Status',
  components: { Tooltip },
  props: {
    text: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    type: {
      type: String as PropType<EStatusType>,
      default: EStatusType.default,
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    const {
      type,
      hasBackground,
      tooltip,
    } = toRefs(props);

    const {
      extraClasses,
      isTooltipVisible,
    } = useView({
      hasBackground,
      tooltip,
      type,
    });

    const hasIconSlot = useHasSlot(slots, 'icon');

    return {
      extraClasses,
      isTooltipVisible,
      hasIconSlot,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
