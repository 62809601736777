import { Ref, computed } from 'vue';

import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import useStore from '@/store/useStore';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { TSubscription } from '@/types';

type TUseView = {
  isPublicOfferAccept: Ref<boolean>,
  isManyEmployeesAlertShown: Ref<boolean>,
  isEmailConfirmed: Ref<boolean>,
  planCode: Ref<EBillingPlanCode | null>,
};

export const useView = (
  {
    isPublicOfferAccept,
    isManyEmployeesAlertShown,
    isEmailConfirmed,
    planCode,
  }: TUseView,
) => {
  const store = useStore();

  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);

  const isBillingValidFrom1MarchAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March));

  const isSubmitDisabled = computed(() => !isPublicOfferAccept.value
  || isManyEmployeesAlertShown.value
  || !isEmailConfirmed.value);

  const hasActiveSubscription = computed(
    () => !!currentTenantInfo.value?.subscriptions?.find(
      (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active,
    ),
  );

  const hasPastSubscriptions = computed(() => currentTenantInfo.value?.hasPastSubscriptions || false);

  const isBillingTrialPeriodInfoShown = computed(
    () => !hasPastSubscriptions.value
    && !hasActiveSubscription.value
    && planCode.value !== EBillingPlanCode.carrierStart,
  );

  return {
    isSubmitDisabled,
    isBillingValidFrom1MarchAvailable,
    isBillingTrialPeriodInfoShown,
  };
};
