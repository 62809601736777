import { RouteRecordRaw } from 'vue-router';

import useTargetTenant from '@/composables/application/useTargetTenant';
import router from '@/router';
import { notification } from '@/utils';
import useInitialLoadingState from '@/composables/application/useInitialLoadingState';
import checkRoutePermissions from '@/router/utils/checkRoutePermissions';
import { addProtectedRouteToStorage, addRejectedRouteName } from '@/router/utils/processRejectedRoute';
import tt from '@/i18n/utils/translateText';
import { ROUTE_PERMISSION_REJECTION_REASON, ROUTE_TYPE } from '@/constants';
import store from '@/store';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';

const { onLoadingFinished } = useInitialLoadingState();
const { processTargetTenant } = useTargetTenant();

// Функция для проверки доступности роута для тенанта.
// Проверяет все ли необходимые данные загружены, установлен ли текущий тенант
// и есть ли permissions на доступ к роуту
const checkRouteBeforeEnter = (route: any) => onLoadingFinished
  .then(processTargetTenant)
  .then(() => checkRoutePermissions(route)
    .then(() => Promise.resolve(true))
    .catch((reason) => {
      // TODO: вернуть реализацию через ключ underPaywall, если должно что-то закрыто под пейволом
      if (reason === ROUTE_PERMISSION_REJECTION_REASON.underPaywall && route.meta.paywallRootRoute) {
        router.replace(route.meta.paywallRootRoute);
      } else if (reason === ROUTE_PERMISSION_REJECTION_REASON.unaccepted) {
        addRejectedRouteName(route.name);
        /**
         * TODO: что делать, если страница не под пэйволом ? Пока редирект и нотификашка
         */
        const isRoutePrivate = route.meta.type === ROUTE_TYPE.private;
        const isAuthenticated = store.getters['app/isAuthenticated'];

        /**
           * Если неавторизованный пользователь пытается перейти к приватному роуту
           * сохраняем url, чтобы после авторизации произошел редирект на нужный роут
           */
        if (isRoutePrivate && !isAuthenticated) {
          store.commit('app/SET_LAST_PATH_BEFORE_AUTH', route.fullPath);
        } else {
          redirectToInitialRoute();
          notification.error({ message: tt('shared.error.forbiddenError') });
        }
      }
      return Promise.resolve(false);
    }));

/** Функция-обертка, возвращающая объект с роутом, в котором
 *  в качестве компонента подставляется кастомный лоадер компонента.
 *  Также функция управляет доступом к роуту через beforeEnter.
 *  При использовании этой функции, component роута нужно передавать в объект meta */
export const buildProtectedRoute = (route: any) => {
  const {
    path, name,
    meta, redirect,
    children = [] as any[],
  } = route;

  const protectedRoute: RouteRecordRaw = {
    path,
    name,
    meta,
    redirect,
    children,
    component: route.meta.component,
    beforeEnter: (to) => checkRouteBeforeEnter(to),
  };

  // Добавляем все роуты с ограниченным доступом и их подроуты
  addProtectedRouteToStorage(protectedRoute, name);

  if (children?.length) {
    children.forEach((childrenRoute) => {
      if (childrenRoute.name && !childrenRoute.meta?.permissions?.length) {
        addProtectedRouteToStorage(protectedRoute, childrenRoute.name);
      }
    });
  }

  return protectedRoute;
};
