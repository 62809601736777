import 'ant-design-vue/dist/antd.variable.min.css';
import '@/styles/app.scss';

import { ConfigProvider } from 'ant-design-vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import AppTitle from '@/components/AppTitle/index.vue';
import AppLayout from '@/components/layouts/AppLayout/index.vue';
import AppTableLayout from '@/components/layouts/AppTableLayout/index.vue';
import { toCurrency } from '@/utils';
import { useAppSentry } from '@/composables/application/useAppSentry';
import { i18n } from '@/i18n';
import useAppTheme from '@/composables/application/useAppTheme';

import App from './App.vue';
import { clickOutside, infiniteScroll } from './directives';
import router from './router';
import store, { storeKey } from './store';

const { sentryInit } = useAppSentry();

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const { getThemeConfig } = useAppTheme();
ConfigProvider.config({ theme: getThemeConfig().ant });

sentryInit(app);
app
  .component('AppTitle', AppTitle)
  .component('AppLayout', AppLayout)
  .component('AppTableLayout', AppTableLayout)
  .directive('click-outside', clickOutside)
  .directive('infinite-scroll', infiniteScroll)
  .use(router)
  .use(store, storeKey)
  .use(pinia)
  .use(i18n)
  .mixin({ methods: { toCurrency } })
  .mount('#app');
