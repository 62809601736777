import { ROUTE_TYPE } from '@/constants';
import {
  EMPLOYEE_ROUTE_NAME,
  VEHICLE_ROUTE_NAME,
} from '@/contexts/resourcesControlContext/domain/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import {
  hasMakeFreightRequestsPermission,
  hasExecuteRunsPermission,
  hasCarrierWorkplaceSubscription,
  hasCargoOwnerWorkplaceSubscription,
  hasShipperWorkplaceSubscription,
  hasCarrierStarterWorkplaceSubscription,
} from '@/domains/permissions/subscription';
import { canBrowseEmployeesByRole } from '@/domains/permissions/role/employees';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

const isExecutorResponsibleEmployeeAvailable = () => checkFeatureIsEnabled(
  EExperimentalFeatures.executorResponsibleEmployee,
);

const hasExecuteRunsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCarrierWorkplaceSubscription()
    : hasExecuteRunsPermission());

const hasMakeFreightRequestsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    : hasMakeFreightRequestsPermission());

const hasNotificationsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCargoOwnerWorkplaceSubscription()
    || hasCarrierWorkplaceSubscription()
    || hasShipperWorkplaceSubscription()
    || hasCarrierStarterWorkplaceSubscription()
    : true);

const routes = [
  buildProtectedRoute({
    path: '/employees',
    name: EMPLOYEE_ROUTE_NAME.employees,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowseEmployeesByRole],
      component: () => import('@/contexts/resourcesControlContext/views/employees/EmployeesListView/index.vue'),
    },
    redirect: '/employees/all',
    children: [
      {
        path: 'all',
        name: EMPLOYEE_ROUTE_NAME.employeesAll,
        component: () => import('@/contexts/resourcesControlContext/views/employees/EmployeesListView/components/EmployeesTable/index.vue'),
      },
      buildProtectedRoute({
        path: 'responsible',
        name: EMPLOYEE_ROUTE_NAME.employeesResponsible,
        meta: {
          type: ROUTE_TYPE.private,
          component: () => import('@/contexts/resourcesControlContext/views/employees/EmployeesListView/components/EmployeesResponsibleTable/index.vue'),
          permissions: [isExecutorResponsibleEmployeeAvailable, hasExecuteRunsSubscription],
        },
      }),
    ],
  }),
  buildProtectedRoute({
    path: '/employees/:id',
    name: 'employeesShow',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowseEmployeesByRole],
      component: () => import('@/contexts/resourcesControlContext/views/employees/EmployeeShowView/index.vue'),
    },
    children: [{
      path: '/employees/:id/information',
      name: EMPLOYEE_ROUTE_NAME.employeeInformationView,
      meta: { type: ROUTE_TYPE.private },
      component: () => import('@/contexts/resourcesControlContext/views/employees/EmployeeInformationView/index.vue'),
    }, {
      path: '/employees/:id/notifications',
      name: EMPLOYEE_ROUTE_NAME.employeeNotificationSettingsView,
      meta: {
        type: ROUTE_TYPE.private,
        permissions: [hasNotificationsSubscription],
      },
      component: () => import('@/contexts/resourcesControlContext/views/employees/NotificationSettingsView/index.vue'),
    }],
  }),
  buildProtectedRoute({
    path: '/vehicles',
    name: 'vehicles',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [hasExecuteRunsSubscription],
      component: () => import('@/contexts/resourcesControlContext/views/vehicles/VehiclesListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/vehicles/:id',
    name: VEHICLE_ROUTE_NAME.vehicleView,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [() => hasExecuteRunsSubscription() || hasMakeFreightRequestsSubscription()],
      component: () => import('@/contexts/resourcesControlContext/views/vehicles/VehiclesShowView/index.vue'),
    },
    children: [{
      path: '/vehicles/:id/information',
      name: VEHICLE_ROUTE_NAME.vehicleInformationView,
      meta: { type: ROUTE_TYPE.private },
      component: () => import('@/contexts/resourcesControlContext/views/vehicles/VehiclesInformationView/index.vue'),
    }],
  }),
];

export default routes;
