<template>
  <div class="support-menu">
    <Popover
      trigger="hover"
      overlayClassName="support-menu__popover"
      placement="rightTop"
      destroyTooltipOnHide
      :visible="isVisiblePopover"
      @visibleChange="handleVisibleChange"
    >
      <div class="support-menu__link">
        <MenuIconWithLabel
          :class="{ 'ant-menu-inline-collapsed': isCollapsed }"
          :icon="icon"
          :label="tt('sider.menu.support')"
        />
      </div>
      <template #content>
        <Menu :selectable="false">
          <MenuItem key="phone">
            {{ phone }}
          </MenuItem>
          <MenuItem key="email">
            <SmartLink :href="`mailto:${email}`">
              {{ email }}
            </SmartLink>
          </MenuItem>
          <MenuItem>
            <SmartLink
              v-if="!isAplTheme"
              :href="instructionLink"
              alwaysOpenNewTab
            >
              {{ tt('sider.menu.instructions') }}
            </SmartLink>
          </MenuItem>
        </Menu>
      </template>
    </Popover>
  </div>
</template>

<script lang="ts">
import {
  Popover,
  Menu,
  MenuItem,
} from 'ant-design-vue';
import {
  computed,
  defineComponent,
  ref,
} from 'vue';

import HelpSvg from '@/assets/svg/16x16/help.svg';
import tt from '@/i18n/utils/translateText';
import MenuIconWithLabel from
  '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/components/MenuIconWithLabel/index.vue';
import useAppTheme from '@/composables/application/useAppTheme';
import { EExecutionRequestSide } from '@/contexts/freightExecutionContext/constants';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
  hasExecuteRunsPermission,
  hasMakeFreightRequestsPermission,
} from '@/domains/permissions/subscription';
import SmartLink from '@/components/SmartLink/index.vue';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import { INSTRUCTION_LINKS } from './constants';

export default defineComponent({
  name: 'SupportMenu',
  components: {
    SmartLink,
    MenuIconWithLabel,
    Popover,
    Menu,
    MenuItem,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { getThemeConfig, currentTheme } = useAppTheme();
    const { support } = getThemeConfig();

    const isVisiblePopover = ref<boolean>(false);

    const handleVisibleChange = () => {
      isVisiblePopover.value = !isVisiblePopover.value;
    };

    const isNewSubscriptionsFeaturesAvailable = computed(
      () => checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March),
    );

    const instructionLink = computed(() => {
      const isCarrierWorkplace = isNewSubscriptionsFeaturesAvailable.value
        ? hasCarrierWorkplaceSubscription()
        : hasExecuteRunsPermission();

      const isCargoOwnerWorkplace = isNewSubscriptionsFeaturesAvailable.value
        ? hasCargoOwnerWorkplaceSubscription()
        : hasMakeFreightRequestsPermission();

      if (isCargoOwnerWorkplace) {
        return INSTRUCTION_LINKS[EExecutionRequestSide.customer];
      }
      if (isCarrierWorkplace) {
        return INSTRUCTION_LINKS[EExecutionRequestSide.executor];
      }

      return INSTRUCTION_LINKS.withoutCompany;
    });

    const isAplTheme = currentTheme === 'apl';

    return {
      icon: HelpSvg,
      isVisiblePopover,
      phone: support.phone,
      email: support.email,
      instructionLink,
      isAplTheme,

      handleVisibleChange,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
