import { EStatusType } from '@/domains/constants';

export const ROUTE_NAME = {
  selfBilling: 'selfBilling',
  selfBillingRegistry: 'selfBillingRegistry',
};

export enum ESelfBillingStatus {
  draft = 'draft',
  verification = 'verification',
  accepted = 'accepted',
  canceled = 'canceled',
}

export enum ERegistryCustomResponseCode {
  runsAlreadyAddedInOtherRegistry = 'DomainErrors::SelfBillingContext::RunsAlreadyAddedInOtherRegistry',
  runsNotFinished = 'DomainErrors::SelfBillingContext::RunsNotFinished',
  runsWithDifferentCurrencies = 'DomainErrors::SelfBillingContext::RunsWithDifferentCurrencies',
  runsWithMissingRequiredAttributes = 'DomainErrors::SelfBillingContext::RunsWithMissingRequiredAttributes',
  cannotStartRegistryVerification = 'DomainErrors::SelfBillingContext::CannotStartRegistryVerification',
}

// TODO После реализации бэком отправки реестра на проверку попросить у них возможные статусы проверки документов и пополнить этот enum
export enum ESelfBillingDocumentsVerificationStatus {
  notVerified = 'notVerified',
}

export const SELF_BILLING_STATUS_TYPES = {
  [ESelfBillingStatus.draft]: EStatusType.default,
  [ESelfBillingStatus.verification]: EStatusType.processing,
  [ESelfBillingStatus.accepted]: EStatusType.success,
  [ESelfBillingStatus.canceled]: EStatusType.error,
};
