import * as types from './mutation-types';
import { TTendersMutations } from './types';

const mutations: TTendersMutations = {
  [types.SET_EXECUTOR_ANSWERS](state, payload) {
    state.executorAnswers = payload;
  },
};

export default mutations;
