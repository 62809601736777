import { ROUTE_TYPE } from '@/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import {
  hasParticipateInLookupsPermission,
  hasPriceAnalyticsPermission,
  hasPriceCalculatorSubscription,
} from '@/domains/permissions/subscription';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';

const hasTransportationCalculatorSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasPriceCalculatorSubscription()
    : hasParticipateInLookupsPermission() && hasPriceAnalyticsPermission());

const routes = [
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: { type: ROUTE_TYPE.public },
    component: () => import('@/views/Infrastructure/MaintenanceView/index.vue'),
  },
  {
    path: '/invitations/:id/acceptance',
    name: 'invitationAcceptance',
    meta: { type: ROUTE_TYPE.private },
    component: () => import('@/views/Invitations/index.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    meta: { type: ROUTE_TYPE.public },
    component: () => import('@/views/UnsubscribingFromEmails/index.vue'),
  },
  buildProtectedRoute({
    path: '/transportation_calculator',
    name: 'transportationCalculator',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [hasTransportationCalculatorSubscription],
      component: () => import('@/views/TransportationCalculatorView/index.vue'),
    },
  }),
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    meta: { type: ROUTE_TYPE.public },
    component: () => import('@/views/NotFoundView/index.vue'),
  },
];

export default routes;
