import * as types from './mutation-types';
import { TTendersActions } from './types';

const actions: TTendersActions = {
  setExecutorAnswers: ({ commit }, payload) => {
    commit(types.SET_EXECUTOR_ANSWERS, payload);
  },
};

export default actions;
