import { InjectionKey } from 'vue';
import {
  createLogger,
  createStore,
} from 'vuex';
import VuexPersist from 'vuex-persist';

import modules from './modules';
import { TCustomStore } from './types';

const getLoggerPlugin = () => (import.meta.env.VITE_APP_STORE_LOGGER_ENABLED === 'true' ? [createLogger()] : []);

const vueLocalStorage = new VuexPersist({
  // под таким ключом будет лежать стейт в localStorage
  key: 'TMS_STORAGE_KEY',
  // только на этот тип мутаций будет срабатывать persist
  filter: (mutation) => [
    'app/SET_TOKENS',
    'app/DELETE_TOKENS',
    'app/SET_COLLAPSED',
    'app/SET_LEGISLATIVE_REMARK_VISIBLE',
    'app/SET_LAST_PATH_BEFORE_AUTH',
    'app/SET_WAS_AUTH_BY',
    'app/SET_FOLDER_OPEN_KEYS',
    'app/SET_SBER_PARAMS',
    'app/SET_LAST_DATA_BEFORE_AUTH',
    'app/SET_APP_SETTINGS',
    'tenants/SET_CURRENT_TENANT',
    'tenants/SET_TENANT_INFO',
    'tenants/SET_LAST_TENANT_ID',
    'filter/SET_MAIN_FILTER_CURRENT_APPLIED_SET',
    'filter/SET_MAIN_FILTER_CURRENT_APPLIED_PRESET',
    'filter/SET_MAIN_FILTER_CURRENT_EDITABLE_PRESET',
    'filter/RESET_MAIN_FILTER_CURRENT_APPLIED_SET',
    'filter/RESET_MAIN_FILTER_CURRENT_APPLIED_PRESET',
    'filter/RESET_MAIN_FILTER_CURRENT_EDITABLE_PRESET',
    'filter/RESET_FILTER_SELECT_CURRENT',
    'filter/SET_FILTER_SELECT_CURRENT',
    'filter/SET_SUBSECTIONS',
    'filters/SET_FILTERS_ID',
    'filters/SET_MAIN_FILTERS',
    'filters/SET_SORTING',
    'filters/SET_INCIDENT_FILTER',
    'filters/SET_FOLDERS',
    'strategies/SET_CHAIN_ID',
    'tenders/SET_EXECUTOR_ANSWERS',
    'pagination/SET_TABLE_PAGINATION',
    'pagination/RESET_PAGINATION',
    'timer/SET_END_DATE',
    'rightPanel/SET_IS_OPEN_ROUTES',
    'rightPanel/SET_IS_OPEN_RIGHT_PANEL',
    'rightPanel/SET_RIGHT_PANEL_WIDTH',
    'notifications/SET_IS_PERSONALIZED_NOTIFICATIONS_INFO_VISIBLE',
  ].includes(mutation.type),
  // только эта часть стейта будет записана в localStorage
  reducer: (state: TCustomStore['state']) => ({
    app: {
      accessToken: state.app.accessToken,
      accessTokenExpiresAt: state.app.accessTokenExpiresAt,
      refreshToken: state.app.refreshToken,
      refreshTokenExpiresAt: state.app.refreshTokenExpiresAt,
      csrfToken: state.app.csrfToken,
      wasAuthBy: state.app.wasAuthBy,
      isSiderCollapsed: state.app.isSiderCollapsed,
      isLegislativeRemarkVisible: state.app.isLegislativeRemarkVisible,
      folderOpenKeys: state.app.folderOpenKeys,
      pathBeforeAuth: state.app.pathBeforeAuth,
      dataBeforeAuth: state.app.dataBeforeAuth,
      sberParams: state.app.sberParams,
      appSettings: state.app.appSettings,
    },
    tenants: {
      currentTenant: state.tenants.currentTenant,
      currentTenantInfo: state.tenants.currentTenantInfo,
      lastTenantId: state.tenants.lastTenantId,
    },
    filter: state.filter,
    strategies: { persistedStrategies: state.strategies.persistedStrategies },
    tenders: state.tenders,
    pagination: state.pagination,
    timer: state.timer,
    rightPanel: state.rightPanel,
    notifications: state.notifications,
  }),
  modules: [
    'app',
    'tenants',
    'filter',
    'filters',
    'strategies',
    'tenders',
    'pagination',
    'timer',
    'notifications',
  ],
});

export const storeKey: InjectionKey<TCustomStore> = Symbol('store-key');

const storeInstance: TCustomStore = createStore({
  modules,
  plugins: [
    vueLocalStorage.plugin,
    ...getLoggerPlugin(),
  ],
  devtools: true,
});

export default storeInstance;
