import {
  computed,
  Slots,
  VNode,
} from 'vue';

export const useHasSlot = (slots: Readonly<Slots>, slotName: string) => computed(() => {
  if (!slots || !slots[slotName]) {
    return false;
  }

  const slotContent: VNode | undefined = slots[slotName]?.()[0];

  return !!slotContent;
});
